import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SEO from "../../components/seo";
import Skel from "../../components/skel";

const SupportedHardware = ({
  name,
  description,
  setupLink,
  purchaseLink,
  bullets = [],
}) => {
  return (
    <div style={{ marginTop: "2em", marginBottom: "4em" }}>
      <Row>
        <Col md={{ offset: 2, span: 10 }}>
          <h3>{name}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={2}></Col>
        <Col md={7}>
          {description}
          <div>
            <a className="btn btn-success" href={setupLink}>
              {name} Setup Guide &raquo;
            </a>
          </div>
        </Col>
        <Col md={3}>
          <ul className="taplist-features">
            {bullets.map(bull => {
              return <li>{bull}</li>;
            })}
          </ul>
        </Col>
      </Row>
    </div>
  );
};

const HardwarePage = () => (
  <Skel>
    <SEO title="Hardware supported by the Taplist.io Digital Menu System" />

    <main>
      <section className="section gray-bg" style={{ padding: "1.5em" }}>
        <Container>
          <Row className="md-m-25px-b justify-content-center text-center">
            <Col lg={12}>
              <h3 className="h1 m-15px-b">Supported Hardware</h3>
              <p style={{ marginBottom: 0 }}>
                To show your menu on a TV, you'll need to set up the Taplist.io
                app on one of our supported hardware devices.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col md={12}>
              <SupportedHardware
                name="Amazon Fire TV"
                description={
                  <>
                    <p>
                      Taplist.io is fantastic on the low-cost{" "}
                      <b>Amazon Fire TV stick</b>, and other devices in the Fire
                      TV family. This device is inexpensive, widely available,
                      and has proven to be very stable. It is the most popular
                      and <b>top choice</b> of our customers.
                    </p>
                    <p>
                      In addition to its low cost and reliability, we recommend
                      Fire TV because it is easy to find: In many places, you
                      can receive a new device overnight or even in the same
                      day.
                    </p>
                  </>
                }
                bullets={[
                  <>
                    <b>Top choice</b>
                  </>,
                  <>Widely available</>,
                  <>Inexpensive</>,
                  <>Reliable</>,
                ]}
                setupLink="/hardware/fire-tv"
                purchaseLink=""
              />
              <SupportedHardware
                name="Apple TV"
                description={
                  <>
                    <p>
                      Taplist.io also works well on <b>Apple TV</b>{" "}
                      devices. While Apple TV devices are more expensive
                      compared to Fire TV, they are widely available and
                      showcase Apple's reputation for high quality.
                    </p>
                    <p>
                      <em>
                        Note: Certain minor special effect features are
                        currently unavailable on Apple TV.
                      </em>
                    </p>
                  </>
                }
                bullets={[<>Widely available</>, <>Reliable</>]}
                setupLink="/hardware/apple-tv"
                purchaseLink=""
              />
              <SupportedHardware
                name="Raspberry Pi (Advanced)"
                description={
                  <>
                    <p>
                      Raspberry Pi is a low-cost embedded Linux computer platform.
                      Although we do not recommend this option for new customers,
                      it may be a good choice if you're already familiar and experienced 
                      with these devices.
                    </p>
                  </>
                }
                bullets={[<>Widely available</>]}
                setupLink="/hardware/raspberry-pi"
                purchaseLink=""
              />
              {/* <SupportedHardware
                name="Chromecast with Google TV"
                description={
                  <>
                    <p>
                      Taplist.io also works on <b>Chromecast with Google TV</b>{" "}
                      devices. Instead of "casting" to these devices, you
                      download and run the Taplist.io app directly on it. This
                      ensures your menu is always showing.
                    </p>
                    <p>
                      Google's track record with TV hardware and software
                      support is not as strong as Apple's and Amazon's, so this
                      device is our third choice.
                    </p>
                  </>
                }
                bullets={[<>Good availability</>, <>Reliable</>]}
                setupLink="/hardware/chromecast-google-tv"
                purchaseLink=""
              /> */}
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <p className="text-center">
                <em className="text-muted">
                  For advanced users, we also provide instructions and limited
                  support for running on{" "}
                  <a href="/hardware/raspberry-pi">Raspberry Pi</a>.
                  devices.
                </em>
              </p>
              <p className="text-center">
                <em className="text-muted">
                  Support for digital signage players and custom devices is available for enterprise
                  customers. Please <a href="mailto:info@taplist.io">contact sales</a> for more information.
                </em>
              </p>
           </Col>
          </Row>
        </Container>
      </section>

      <section className="section gray-bg" style={{ padding: "1.5em" }}>
        <Container>
          <Row className="md-m-25px-b justify-content-center text-center">
            <Col lg={12}>
              <h3 className="h1 m-15px-b">Supported TVs</h3>
              <p style={{ marginBottom: 0 }}>
                What TVs is Taplist.io compatible with?
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section" style={{ paddingTop: "1.0em" }}>
        <Container>
          <Row>
            <Col md={2}></Col>
            <Col md={10}>
              <h3>Basic TV Requirements</h3>
              <p>
                Taplist.io works with <b>most modern TVs</b>. Minimum
                requirements:
              </p>
              <ul>
                <li>
                  HDMI input, connected to one of the supported Playback
                  Devices.
                </li>
                <li>1080p resolution capable.</li>
              </ul>
              <p>
                For a top-of-the-line install, we recommend using a{" "}
                <em>commercial display</em>. These devices are built and
                certified for the demands of digital signage. However, most of
                our customers use basic, off-the-shelf retail TVs without any
                reported issues.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={2}></Col>
            <Col md={10}>
              <h3>About Smart TVs</h3>
              <p>
                A Smart TV is not required. In fact, because there are so many
                different types of Smart TVs, and because their quality and
                capabilities vary so widely,{" "}
                <b>
                  we do not recommend or provide support for running Taplist.io
                  directly on a smart TV
                </b>
                , unless the TV is itself a Fire TV device.
              </p>
              <p>
                Instead, we recommend purchasing and using one of the playback
                devices above, regardless of your panel's Smart TV support.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section gray-bg" style={{ padding: "1.5em" }}>
        <Container>
          <Row className="md-m-25px-b justify-content-center text-center">
            <Col lg={12}>
              <h3 className="h1 m-15px-b">Got Questions?</h3>
              <p style={{ marginBottom: '0.5em' }}>
                Not sure what option is best for you, or still have questions?
                Contact our support team and we will help you out!
              </p>
              <p>
              <a
                href="mailto:support@taplist.io"
                className="btn btn-success btn-lg"
              >
                Contact Support
              </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  </Skel>
);

export default HardwarePage;
